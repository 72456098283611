<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-03 09:10:42
 * @listEditTime: Do not edit
-->
<template>
  <div class="pdfStyle">
    <!--    <h3 style="text-align: center">{{title}}-{{srcList}}</h3>-->
    <div class="pdfTextData"  :style="{width:carWidth.width == '400px'?'100%':'',marginRight:carWidth.width == '400px'?'0px':''}">
      <div v-for="(item, index) in srcList" :key="index" @click="pdfListClickFunc(item,index)" :class="{displayBg:currentIndex === index}"  >
        <div class="pdfListBox">
         
          <img :src="item.icon&&item.icon[0]" alt="" v-if="item.icon&&item.icon.length>0">
          <!-- <img src="./test.png" alt="" v-if="item.icon">  -->
          <div>
           
            <div class="titleBox" >{{ item.title&&item.title.replace(".pdf","") }}</div>
            <div class="dept">处室：{{ item.deptName }}</div>
          </div>
        </div>
            <!-- <el-divider></el-divider> -->
      </div>

    </div>
    <!-- @click="pdflookFunc(pdfUrlType?pdfUrlType:srcList[0].src)" -->
    <div style="width: 80%; height: 80vh; overflow-y: scroll" v-if="carWidth.width == '400px'?false:true" >
      <div  class="downLoad" >
        <el-button type="primary" @click="pdflookFunc(pdfUrlType?pdfUrlType:srcList[0].src)">全屏</el-button>
        <el-button type="primary" v-if="fileUrl" @click.stop="downLoad(fileUrl)">下载附件</el-button>
      </div>
      <embed :src="pdfUrlType?pdfUrlType:srcList[0].src" style="width: 100%; height: 800px"/>
      
    </div>
    <el-dialog :close-on-click-modal="false" title="预览" :visible.sync="visible" :append-to-body="true" :lock-scroll="false" fullscreen @closed="close" append-to-body>
      <div class="close-view" @click="visible=false">
         <el-button type="primary">关闭浏览</el-button>
      </div>
      <pdf-viewer :src="dialogSrc" :startPage="startPage" :style="{ height: height, width: '100%' }"></pdf-viewer>
    </el-dialog>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import pdfViewer from './viewer'
export default {
  components: {
    pdf,
    pdfViewer,
  },
  props: {
    srcList: {
      type: Array,
      default: () => {
        return []
      },
    },
    startPage: {
      type: Number,
      default: 1,
    },
    carWidth:{
      type: Object,
      default: {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogSrc: '',
      fileUrl:'',
      visible: false,
      height: '90vh',
      pdfUrlType:"",
      currentIndex:0
    }
  },
  mounted(){
    console.log(this.srcList)
   if(this.srcList[0].fileList&&this.srcList[0].fileList.length>0){
    this.fileUrl = this.srcList[0].fileList[0].url
   }else{
    this.fileUrl = ''
   }
   
  },
  methods: {
    pdflookFunc(src) {
      this.dialogSrc = src
      this.visible = true
    },
    close() {
      this.dialogSrc = ''
    },
    downLoad(url){
      console.log(url)
      let winHandler = window.open("", name);
      winHandler.location.href = url;
    },
    /**
     * @Author: yangxin
     * @Date: 2024-08-30 12:03:00
     * @test: test font
     * @msg: 点击列表生成左侧
     * @param {*} item
     * @return {*}
     */
    pdfListClickFunc:function(item,index){
      this.currentIndex = index
      if(this.carWidth.width == '400px'){
          this.dialogSrc = item.src
          this.visible = true
      }
      this.pdfUrlType = item.src
     console.log(item.fileList.length)
      if(item.fileList&&item.fileList.length>0){
        this.fileUrl = item.fileList[0].url
      }else{
          this.fileUrl = ''
        }
     
    },

    mouseoverFunc:function(index){
      console.log(index)
      this.currentIndex = index
    },

    maxScreen(a) {
      if (a) {
        this.height = '500px'
      } else {
        this.height = window.innerHeight - 65 + 'px'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// /deep/ canvas{
//   height: 233px !important;
// }
.pdf {
  cursor: pointer;
  width: 100%;
  height: 20%;
}
// 滚动条宽度
div::-webkit-scrollbar {
  width: 6px;
}
// 滚动条轨道
div::-webkit-scrollbar-track {
  background: rgba(239, 239, 239, 0.3);
  border-radius: 2px;
}
// 小滑块
div::-webkit-scrollbar-thumb {
  background: #40a0ff49;
  border-radius: 10px;
}

.displayBg{
  background:rgba(#ffffff, $alpha: 0.1) ;

}
.pdfTextData {
  width: 30%;
  height: 100%;
  margin-right: 20px;
  overflow-y: scroll;
  .pdfListBox{
    width: 100%;
    padding: 16px 16px 16px 10px;
    border-radius:10px;
    border-bottom: 1px solid #014E5E;
    display: flex;
      align-items: center;
    >div{
      
    }
    // margin-bottom:10px;
    .titleBox{
      font-size: 18px;
      font-weight: 500;

    }
    img{
      width: 60px;
      height: 60px;
      border-radius: 6px;
      margin-right: 10px;
      flex: 0 0 60px;
    }
    .dept{
      margin-top:5px;
      font-size: 13px;
    }
  }
}
.pdfStyle {
  width: 100%;
  height: 700px;

  display: flex;
}
.downLoad{
  position: absolute;
  bottom:30px;
  right:30px;
}
.close-view{
  position: absolute;
  top:15px;
  left: 75px;
}
</style>
